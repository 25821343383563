import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  useEffect(() => {
      AOS.init({
        duration: 1000,
        once: true,
      });
    }, []);
  
  return (
    <><div id="home" className="">
      <img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
       <Navbar/>
      </div>
   
    <div className="md:p-20 p-10">
    
<div className="md:ps-5 ">













<div className="md:flex     text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-3xl md:text-5xl mt-20">OUR PORTFOLIO</h1></div>

</div>


<div className="md:flex  pt-10 mb-20 pb-20 justify-evenly text-white ">
<Link to="/residential">
<div className="mt-5 w-[300px] h-[250px] md:w-[500px] mb-20" >
  <img data-aos="fade-up" src={require("../assets/project1.jpg")} className="w-[100%] h-[100%] md:w-[500px]"></img>
  <div className="bg-stone-800 p-3 w-[100%px] h-[50px] md:w-[100%]"><p className="text-center">Residential</p></div>
</div>
</Link>
<Link to="/commercial">
<div className="mt-5 w-[300px] h-[250px] md:w-[500px]">
  <img data-aos="fade-up" src={require("../assets/project2.jpg")} className="w-[100%] h-[100%] md:w-[500px]"></img>
  <div className="bg-stone-800 p-3 w-[100%px] h-[50px] md:w-[100%]"><p className="text-center">Commercial</p></div></div>
  </Link>


</div>














<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>

</div>
</div>
     <div>
<Footer/>
     </div>
    </>
  );
}
