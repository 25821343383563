import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);


  return (
<div>
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

      <Navbar/>
      

      <div className="md:p-10">
    <div className="md:flex   text-white">
    
<div className="mt-10"><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-[80px] m-10">Park Avenue Apartments</h1></div>
</div>
</div><div>
</div>
      </div>
      















<div className="p-10  space-y-6">

{/* Collage 1: Large Left, Two Small Right */}
<div className="flex flex-row gap-4 justify-center items-stretch">
  {/* Large Image */}
  <div className="w-1/2 transition-transform duration-500 hover:scale-105">
    <img data-aos="fade-up"
      src={require("../assets/Portfolio/2. Commercial/1. Buildings/5. Park Avenue Apartments/ASA_0885 as Smart Object-1.jpg")}
      className="h-[500px] w-full object-cover border-4 border-[#999] rounded-lg shadow-lg"
      alt=""
    />
  </div>

  {/* Two Small Images */}
  <div className="w-1/2 flex flex-col gap-4">
    <img data-aos="fade-up"
      src={require("../assets/Portfolio/2. Commercial/1. Buildings/5. Park Avenue Apartments/ASA_0894 as Smart Object-1.jpg")}
      className="h-[244px] w-full object-cover border-4 border-[#999] rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
      alt=""
    />
    <img data-aos="fade-up"
      src={require("../assets/Portfolio/2. Commercial/1. Buildings/5. Park Avenue Apartments/ASA_1017 as Smart Object-2.jpg")}
      className="h-[244px] w-full object-cover border-4 border-[#999] rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
      alt=""
    />
  </div>
</div>

{/* Collage 2: Two Small Left, Large Right */}
<div className="flex flex-row gap-4 justify-center items-stretch">
  {/* Two Small Images */}
  <div className="w-1/2 flex flex-col gap-4">
    <img data-aos="fade-up"
      src={require("../assets/Portfolio/2. Commercial/1. Buildings/5. Park Avenue Apartments/ASA_1026 as Smart Object-1.jpg")}
      className="h-[244px] w-full object-cover border-4 border-[#999] rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
      alt=""
    />
  </div>

 

</div>







<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div>
<Footer/>
     </div>
    </div>
  );
}

