import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const teamMembers = [
    { name: "SAMAR ALI KHAN", role: "Principal Architect", image: "Samar Ali Khan.png" },
    { name: "SUBHAN ALI KHAN", role: "Strategic Director", image: "Subhan Ali Khan.png" },
    { name: "MOHAMMAD RAZA", role: "Director Construction", image: "Mohammad Raza.png" },
    { name: "SALAR ALI KHAN", role: "Head of Operations", image: "Salar Ali Khan.png" },
    { name: "SAMINA RAZA", role: "Client Services Director", image: "Samina Raza.png" },
    { name: "HASAN RAZA", role: "Accounts Manager", image: "Hasan Raza.png" },
    { name: "RAFI AHMED", role: "Senior Architect", image: "Rafi Ahmed.png" },
    { name: "RAFIA DADI", role: "Senior Architect", image: "Rafia Dadi.png" },
    { name: "HARIS QURESHI", role: "Associate Architect", image: "Haris Qureshi.png" },
    { name: "ZAIN UL HASSAN", role: "Senior Engineer", image: "Zain-ul-Hassan.png" },
    { name: "MUHAMMAD WASEEM", role: "Senior Draftsman", image: "Muhammad Waseem.png" },
    { name: "HAMZA FATEH", role: "Draftsman", image: "Hamza Fateh.png" },
    { name: "AAKASH DEVJEE", role: "Site Supervisor", image: "Aakash Devjee.png" },
    { name: "FARHAN KIYANI", role: "Site Supervisor", image: "Farhan Kiyani.png" },
    { name: "SHAFAY RAZA", role: "Junior Draftsman", image: "Shafay Raza.png" },
    { name: "UMER AHMED", role: "Junior Engineer", image: "Muhammad Umair.png" },
    { name: "MUHAMMAD UMAIR", role: "Administration Executive", image: "Umer Ahmed.png" },
    { name: "MANHOJ", role: "Maintenance Officer", image: "Manhoj.png" }
  ];

return (
<div>
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

     <Navbar/>

     <div className="md:flex  p-20  text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20">OUR TEAM</h1></div>

</div>



      </div>
      <div className="md:ps-10 md:pe-10 ">




      <div className="grid grid-cols-3 md:grid-cols-6 gap-4 p-10 text-white">
  {teamMembers.map((member, index) => (
    <div key={index} className="text-center">
      <img
        data-aos="fade-up"
        src={require(`../assets/ourteam/${member.image}`)}
        className="object-cover mx-auto "
        height={200}
        width={200}
        alt={member.name}
      />
      <h4 style={{ fontFamily: "Oswald", fontSize:14, letterSpacing: 3,paddingTop:5 }}>{member.name}</h4>
      <p className="mb-20" style={{ color: "#828282", fontSize:12, fontFamily: "Oswald", letterSpacing: 3 }}>
        {member.role}
      </p>
    </div>
  ))}
</div>

















<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div>
<Footer/>
     </div>
    </div>
  );
}
